.bannerimg-container {
    position: relative;
    width: 100%;
    height: 400px;
    overflow: hidden;
}
.bannerimg-container span {
    width: 100%;
    margin-top: 0;
    height: 400px;
}
.bannerimg-container img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.bannerimg-container p {
    position: absolute;
    background-color: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(4px);
    display: block;
    font-size: 15px;
    line-height: 19px;
    padding: 11px 7px;
}

.bannerimg-recepie {
    display: block;
    width: 100%;
    margin: 0 auto;
    object-fit: cover;
    object-position: 50% 50%;
    height: 276px;
    margin-bottom: 18px;
}

#level-tag {
    top: 103px;
    left: 0;
}
#punkte-tag {
    top: 103px;
    right: 0;
}
#koch-tag {
    bottom: 30px;
    left: 0;
}
#super-tag {
    bottom: 30px;
    right: 0;
}

.title-box {
    display: flex;
    max-width: 100%;
    margin-bottom: 52px;
    padding: 0 10px;
    justify-content: center;
    align-content: center;
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(4px);
    padding-top: 30px;
}

.title-box p {
    display: inline-block;
    width: 164px;
    padding: 5px 0;
    margin: auto;
    flex: 1;
    text-align: center;
    padding: 0 10px;
}

#recepie-section2 .headingText {
    text-align: left;
    margin-left: 33px;
    margin-bottom: 16px;
}

#recepie-section2 .paragraphText {
    text-align: left;
    margin-bottom: 29px;
}

#selector-table {
    width: 100%;
}

#selector-table,
#selector-table td {
    border: 1px solid #6f6f6f;
    border-collapse: collapse;
}

#selector-table td {
    width: 103.5px;
    max-width: 25%;
    height: 59px;
    background-color: #fbfbfb;
    box-sizing: border-box;
    text-align: center;
    vertical-align: middle;
    color: #6f6f6f;
    font-size: 17px;
    line-height: 24px;
}

#selector-table td:hover {
    background-color: #ffffff;
    border: 1px solid #ffacad;
    color: #333333;
}
ul {
    border-left: 5px solid #ffacad;
}
ul p {
    margin: 48px 0 22px 33px;
    font-size: 17px;
    line-height: 140.2%;
    /* identical to box height, or 24px */
    font-family: "SuisseScreenMedium";
    text-decoration-line: underline;
    color: #333333;
}

li {
    margin-left: 50px;
    margin-bottom: 7px;
    padding-left: 15px;
    font-size: 17px;
    line-height: 24px;
    /* identical to box height */

    color: #000000;
}

#recepie-section4 .headingText {
    text-align: left;
    margin: 70px 0 45px 34px;
}

#recepie-section4 .paragraphText {
    text-align: left;
    margin-top: 15px;
    margin-bottom: 56px;
}

#recepie-section4 span {
    font-family: "SuisseScreen";
    font-size: 17px;
    line-height: 24px;
    text-align: left;
    font-weight: bold;
    text-decoration: underline;
    text-align: left;
    margin: 26px 0 0 36px;
}

#recepie-section3,
#recepie-section4 {
    position: relative;
}

.sidebar {
    position: absolute;
    top: 82px;
}

.topbar {
    position: absolute;
    top: 80px;
}

.anchor-0 {
    position: absolute;
    left: 54.5px;
    top: 63px;
}
.anchor-1 {
    position: absolute;
    left: 154px;
    top: 63px;
}
.anchor-2 {
    position: absolute;
    left: 257px;
    top: 63px;
}
.anchor-3 {
    position: absolute;
    left: 358px;
    top: 63px;
}

.section {
    margin: 0 auto;
}

#info-table,
#info-table td {
    border-collapse: collapse;
}

#info-table {
    /* background-color: #741112; */
    margin-left: 36px;
    width: 311px;
}

#info-table td {
    text-align: left;
    vertical-align: middle;
    border-bottom: 1px solid #000000;
    padding: 10px 0 3px 0;
    color: #000000;
    font-size: 17px;
    line-height: 24px;
}

#recepie-section3 p {
    font-family: "SuisseScreen";
    font-size: 17px;
    line-height: 24px;
    text-align: left;
    font-weight: bold;
    text-decoration: underline;
    text-align: left;
    margin: 48px 0 22px 36px;
}

/* -----------------------------Code for swipe -----------------------------------*/

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.recepie-card {
    width: 100%;
    min-height: 100vh;
    animation: fadeIn 0.5s ease-in-out forwards;
}

.icon {
    width: 53px;
    height: 46px;
    background: rgba(255, 163, 164, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
}

#like {
    width: 30px;
    height: 30px;
}

#dislike {
    width: 20px;
    height: 20px;
    opacity: .8;
}

.big {
    transform: scale(1.5);
    scale: 1.5;
    transition: all 0.2s ease-out;
}
