.bannerimg {
  display: block;
  height: 275px;
  width: 100%;
  /* width: 413px; */
  margin: 0 auto;
  object-fit: cover;
  object-position: 50% 50%;
}

.headingText {
  font-family: "SuisseScreenSemiBold";
  width: 313px;
  margin: 0 auto;
  font-style: normal;
  /* font-weight: 600; */
  font-size: 23px;
  line-height: 30px;
  text-align: center;
  color: #333333;
}

.paragraphText {
  font-family: "SuisseScreenLight";
  padding-left: 30px;
  padding-right: 30px;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 24px;
  text-align: center;
  color: #333333;
}

span {
  display: block;
  margin-top: 10px;
}

.video-container {
  position: relative;
}
.play-button-container {
  position: absolute;
  width: 69px;
  height: 69px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(4px);
  border-radius: 50%;
}

.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-40%, -50%);
}
/* -----------------------------space adjustments----------------------------------------- */
#section1 .headingText {
  padding-top: 31px;
  padding-bottom: 15px;
}
#section2 .headingText {
  /* padding-top: 31px; */
  padding-bottom: 30px;
}
#section4 .headingText {
  padding-top: 63px;
  padding-bottom: 5px;
}
#section5 .headingText {
  padding-top: 30px;
  padding-bottom: 5px;
}
#section6 .headingText,
#section7 .headingText {
  padding-top: 38px;
  padding-bottom: 11px;
}
#section8 .headingText {
  padding-top: 37px;
  /* padding-bottom: 25px; */
}

#section1 .paragraphText {
  padding-bottom: 21px;
}
#section2 .paragraphText {
  padding-bottom: 21px;
}
#section4 .paragraphText {
  padding-bottom: 63px;
}
#section5 .paragraphText {
  padding-bottom: 55px;
}
#section6 .paragraphText {
  padding-bottom: 55px;
}
#section7 .paragraphText {
  padding-bottom: 42px;
}
#section8 .paragraphText {
  padding-bottom: 45px;
}

/* -----------------------------Image height sizing----------------------------------------- */

#section1 .bannerimg {
  height: 275px;
}
/* #section2 .bannerimg{
  height: 242px;
} */
#section3 .bannerimg {
  height: 275px;
}
/* #section4 .bannerimg{
  height: 276px;
} */
#section5 .bannerimg {
  height: 276px;
}
#section6 .bannerimg {
  height: 269px;
}
#section7 .bannerimg {
  height: 274px;
}
#section8 .bannerimg {
  height: 235px;
}

/* -----------------------------Slider----------------------------------------- */
#section3 {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}

#section3 img {
  height: 382px;
}

#slider1, #slider3 {
  transform: scale(.97);
  scale: .97
}
