#emailsection2 {
    width: 312px;
    margin: 0 auto;
    padding-bottom: 88px;
}

.email input {
    width: 312px;
    height: 40px;
    margin: 9px auto;
    padding: 10px;
    background: #ffffff;
    border: 1px solid #333333;
    box-sizing: border-box;
}

.email button {
    display: block;
    width: 312px;
    height: 46px;
    margin: 9px auto;
    border: none;
    font-family: "SuisseScreenSemiBold";
    font-size: 20px;
    line-height: 140.2%;
    background: rgba(255, 163, 164, 0.9);
}

/* -----------------------------space adjustments----------------------------------------- */
#emailsection1 .headingText {
    padding-top: 37px;
    padding-bottom: 38px;
}
#emailsection1 .paragraphText {
    padding-bottom: 49px;
}
