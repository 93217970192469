.options-button {
    display: flex;
    width: 351px;
    height: 54px;
    margin: 0 auto;

    background: #ffffff;
    border: 1px solid #666666;
    box-sizing: border-box;
    border-radius: 64px;
    max-width: 90%;
}

.options-text {
    font-family: "SuisseScreenLight";
    height: 28px;
    margin: auto 0;
    font-size: 20px;
    line-height: 140.2%;
    color: #6f6f6f;
}

.options-text-selected {
    font-family: "SuisseScreenLight";
    height: 28px;
    margin: auto 0;
    font-size: 20px;
    line-height: 140.2%;
    color: #333333;
}

.options-checkbox {
    display: inline;
    width: 20px;
    height: 20px;
    margin: auto 15px auto 20px;
    border: 1px solid #6f6f6f;
    box-sizing: border-box;
    border-radius: 50%;
    transform: matrix(-1, 0, 0, 1, 0, 0);
}

.options-checkbox-selected {
    display: inline;
    width: 20px;
    height: 20px;
    margin: auto 15px auto 20px;
    background: #ffacad;
    border: 1px solid #676767;
    box-sizing: border-box;
    border-radius: 50%;
    transform: matrix(-1, 0, 0, 1, 0, 0);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 80% 60%;
    transform: rotate(369deg);
}

.faq-button {
    /* display: flex; */
    width: 351px;
    /* height: 54px; */
    margin: 0 auto;
    padding: 12px 0;

    background: #ffffff;
    border: 1px solid #666666;
    box-sizing: border-box;
    border-radius: 25px;
    max-width: 90%;
}
.faq-text {
    font-family: "SuisseScreenLight";
    height: 28px;
    margin: auto 0;

    font-weight: 10px;
    font-size: 20px;
    line-height: 140.2%;
    text-decoration-line: underline;
    display: inline-block;
}
#faqs-section .paragraphText {
    display: block;
    clear: both;
}

.faq-arrow {
    margin: auto 25px;
}

.three-dots-container {
    width: 275px;
    margin: 19px auto 20px auto;
}

.dot {
    display: inline-block;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background: #ffffff;
    border: 1px solid #8c8c8c;
    box-sizing: border-box;
    vertical-align: middle;

    font-weight: 600;
    font-size: 18px;
    line-height: 33px;

    text-align: center;

    color: #8c8c8c;
}

.dot:hover,
.dot-open {
    background: #333333;
    color: #ffffff;
}

.arrow {
    margin-left: 10px;
    margin-right: 15px;
}

/* -------------------------------------space adjustments----------------------------------------------------- */
#options-section2 .headingText {
    padding-bottom: 22px;
}
#faqs-section .headingText {
    padding-top: 90px;
    padding-bottom: 32px;
}
#faqs-section {
    padding-bottom: 87px;
}
#options-section1 {
    text-align: center;
}
