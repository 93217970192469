@font-face {
  font-family: "SuisseScreen";
  src: url("../assets/SuisseScreen/SuisseScreen-Regular-WebS.woff2") format("truetype");
}
@font-face {
  font-family: "SuisseScreenLight";
  src: url("../assets/SuisseScreen/SuisseScreen-Light-WebS.woff2") format("truetype");
}
@font-face {
  font-family: "SuisseScreenBold";
  src: url("../assets/SuisseScreen/SuisseScreen-Bold-WebS.woff2") format("truetype");
}
@font-face {
  font-family: "SuisseScreenSemiBold";
  src: url("../assets/SuisseScreen/SuisseScreen-SemiBold-WebS.woff2") format("truetype");
}
@font-face {
  font-family: "SuisseScreenMedium";
  src: url("../assets/SuisseScreen/SuisseScreen-Medium-WebS.woff2") format("truetype");
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "SuisseScreenLight";
  color: #333333;
}

html,
body {
  width: 100vw;
  overflow-x: hidden;
  background: #fbfbfb;
  margin: 0 auto;
  max-width: 600px;
  color: #333333;
}
/* -----------------------------Header starts here -----------------------------------*/
header {
  position: absolute;
  top: 0;
  width: 100%;
  height: 73px;
  z-index: 50;

  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(4px);
  max-width: 600px;
  /* Note: backdrop-filter has minimal browser support */
  /* display: flex; */
  /* left: 50%; */
  /* margin-left: -50%; */
  /* justify-content: center; */
}

.header-container {
  position: relative;
  width: 414px;
  height: 73px;
  max-width: 100%;
  margin: 0 auto;
}

.logo {
  height: 80%;
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
}

.logo-alt {
  height: 40%;
  top: 50%;
}

.shopping-cart {
  position: absolute;
  right: 52px;
  bottom: 7px;
}
.cogwheel {
  position: absolute;
  left: 28px;
  bottom: 7px;
  height: 25px;
  width: 25px;
}

.number {
  font-family: "SuisseScreenBold";
  position: absolute;
  right: 28px;
  bottom: 7px;
}

.header-title {
  display: inline-block;
  margin-top: 40px;
  margin-left: 50%;
  transform: translateX(-50%);
  width: 144px;
  height: 26px;
  text-align: center;

  font-family: "SuisseScreenBold";
  font-size: 20px;
  line-height: 26px;

  text-transform: uppercase;
  color: #000000;
}
/* -------------------------------Header ends here----------------------------------------------- */
/* -------------------------------Footer starts here----------------------------------------------- */
footer {
  display: flex;
  flex-direction: column;
  background: white;
  padding-top: 40px;
  padding-bottom: 100px;
  width: 100%;
  align-items: center;
  justify-content: space-evenly;
  margin: 0 auto;
}
footer button {
  display: block;
  height: 42px;
  margin-bottom: 20px;
  padding: 0 20px 0 20px;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 30px;
  background-color: white;
  font-size: 17px;
}

/* -------------------------------Footer ends here----------------------------------------------- */
/* -------------------------------Redbutton starts here----------------------------------------------- */

.redButton {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  width: 241px;
  bottom: 5px;
  height: 46px;
  margin: 9px auto;
  border: none;
  font-family: "SuisseScreenSemiBold";
  font-size: 20px;
  line-height: 140.2%;
  color: #333333;
  background: rgba(255, 163, 164, 0.9);
}

.redButton-container {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 73px;
  z-index: 50;
  max-width: 600px;

  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(4px);
}

#filter-page .redButton {
  min-width: 350px;
}

/* -------------------------------RedButton ends here----------------------------------------------- */
/* -------------------------------DataPages starts here----------------------------------------------- */

.datapage {
  width: 414px;
  max-width: 100%;
  margin: 0 auto;
}

.datapage h1 {
  font-family: "SuisseScreenBold";
  margin: 114px auto 55px auto;
  font-style: normal;
  font-weight: 600;
  font-size: 23px;
  line-height: 30px;
  text-align: center;
  color: #333333;
  text-transform: uppercase;
}

.datapage h2 {
  font-family: "SuisseScreenBold";
  padding: 0 51px;
  margin-bottom: 25px;
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
}

.datapage h3 {
  font-family: "SuisseScreenBold";
  padding: 0 51px;
  margin-bottom: 25px;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
}

.datapage h4 {
  font-family: "SuisseScreenLight";
  padding: 0 51px;
  margin-bottom: 25px;
  font-weight: 400;
  font-size: 17px;
  line-height: 160%;
  text-decoration-line: underline;
}

.datapage p {
  font-family: "SuisseScreenLight";
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 24px;
  color: #333333;
  line-height: 160%;
  padding: 0 51px;
  margin-bottom: 81px;
}

.less-space p {
  margin-bottom: 40px !important;
}

/* -------------------------------DataPages end here----------------------------------------------- */
